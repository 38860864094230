<template>
    <div class="login-body">
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel p-pr-6 p-pl-6">
                <form @submit.prevent="registerUser" class="login-form">
                    <div :class="inputGroupClasses">
                        <h3 class="login_title">Регистрация пользователя</h3>
                    </div>
                    <div :class="inputGroupClasses" class="p-pb-0">
                        <div class="p-inputgroup p-d-flex p-flex-column p-ai-start p-col-12 p-pblank-0 p-px-0 p-pb-0">
                            <label class="color p-pb-2 p-pl-.5 customLabelColor">Укажите Вашу организацию:</label>
                            <div class="p-inputgroup">
                                <InputText name="tin" v-model="tin" placeholder="Найти по ИНН*" :class="v$.tin.$invalid && submitted ? 'p-error' : ''" @keyup="findOrganization" />
                            </div>
                        </div>
                        <small v-show="submitted && !innError" v-for="error in showErrors(v$.tin)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                        <small class="p-error">{{ innError }}</small>
                    </div>
                    <div :class="inputGroupClasses">
                        <div class="bgc" :class="inputGroupClasses">
                            <p class="p-text-left">{{ organizationName }}</p>
                        </div>
                        <small class="p-error"> {{ organizationByInnError }}</small>
                        <small v-if="organizationMessage" class="p-error"> Организация не найдена. Для регистрации организации перейдите по <router-link to="/registrationOrganization" class="logo"> ссылке </router-link> </small>
                    </div>
                    <div :class="inputGroupClasses">
                        <InputText name="login" v-model.trim="v$.login.$model" placeholder="Ваш логин*" :class="v$.login.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.login)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                        <small class="p-error">{{ userError }}</small>
                    </div>
                    <div class="p-col-12 p-lg-12 p-mb-lg-0 p-py-2 p-px-2">
                        <div class="p-fluid p-grid p-formgrid">
                            <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pr-2">
                                <Password :feedback="false" name="password" v-model.trim="v$.password.$model" placeholder="Ваш пароль*" autocomplete="true" :class="v$.password.$invalid && submitted ? 'p-error' : ''" />
                                <small v-show="submitted" v-for="error in showErrors(v$.password)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                            <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pl-2">
                                <Password
                                    :feedback="false"
                                    name="passwordConfirm"
                                    v-model.trim="v$.passwordConfirm.$model"
                                    placeholder="Подтвердите пароль*"
                                    autocomplete="true"
                                    class="pass"
                                    :class="v$.passwordConfirm.$invalid && submitted ? 'p-error' : ''"
                                />
                                <small v-show="submitted" v-for="error in showErrors(v$.passwordConfirm)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-lg-12 p-mb-lg-0 p-py-2 p-px-2">
                        <div class="p-fluid p-grid p-formgrid">
                            <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pr-2">
                                <InputText name="firstName" v-model.trim="v$.firstName.$model" placeholder="Имя*" :class="v$.firstName.$invalid && submitted ? 'p-error' : ''" />
                                <small v-show="submitted" v-for="error in showErrors(v$.firstName)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                            <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pl-2">
                                <InputText name="lastName" v-model.trim="v$.lastName.$model" placeholder="Фамилия*" :class="v$.lastName.$invalid && submitted ? 'p-error' : ''" />
                                <small v-show="submitted" v-for="error in showErrors(v$.lastName)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-lg-12 p-mb-lg-0 p-py-2 p-px-2">
                        <div class="p-fluid p-grid p-formgrid">
                            <div class="p-field p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pr-2">
                                <InputText name="email" v-model.trim="v$.email.$model" placeholder="Ваш e-mail*" :class="v$.email.$invalid && submitted ? 'p-error' : ''" />
                                <small v-show="submitted" v-for="error in showErrors(v$.email)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-pl-2">
                                <InputText name="telephone" v-model.trim="v$.telephone.$model" placeholder="Ваш контактный телефон*" :class="v$.telephone.$invalid && submitted ? 'p-error' : ''" />
                                <small v-show="submitted" v-for="error in showErrors(v$.telephone)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                            </div>
                        </div>
                    </div>

                    <div class="p-mb-0" :class="inputGroupClasses">
                        <div class="p-field-checkbox p-text-left p-mb-2 p-d-flex p-ai-start">
                            <Checkbox v-model="v$.agreement.$model" :binary="true" name="agreement" id="agreement" :class="v$.agreement.$invalid && submitted ? 'p-error' : ''" />
                            <label for="agreement">
                                Я ознакомлен с <a class="decorateText" href="https://niioz.ru/upload/iblock/f6b/f6b3d8173523b37ebc3371ce3dbd1dec.pdf" target="blank">Условиями использования Сайта</a>,
                                <a
                                    class="decorateText"
                                    href="https://niioz.ru/upload/doc/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%A1%D0%90%D0%99%D0%A2%20NIIOZ.pdf"
                                    target="blank"
                                    >Политикой обработки персональных данных</a
                                >
                                и даю согласие на обработку персональных данных.
                            </label>
                        </div>
                        <small v-show="submitted" v-for="error in showErrors(v$.agreement)" :key="error.$uid" class="p-error">{{ 'Необходимо дать согласие на обработку персональных данных' }}</small>
                    </div>
                    <div class="p-mb-0 p-px-2">
                        <Button class="p-mb-0 p-button p-pl-4 p-pr-4 customPadding" label="Отправить заявку" type="submit" />
                    </div>
                    <div class="p-d-flex p-ai-start p-flex-column p-col-12 p-px-2">
                        <span class="p-py-1 customColor">Уже зарегистрированы?</span>
                        <Button class="p-mr-2 p-mb-0 p-button-text p-px-0 decorateText" @click="$router.push('/login')" label="Войти" />
                    </div>
                    <div class="p-mb-0" :class="inputGroupClasses">
                        <p class="p-text-left p-mb-0">
                            Не нашли свою Организацию в системе? Обратитесь в службу поддержки НИИОЗМ по телефону: <br />
                            <a href="tel:84954171209" target="_blank" class="telColor">8 (495) 417-12-09 доб. 313/323</a>
                        </p>
                    </div>
                </form>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import LoginImage from '@/components/loginComponents/LoginImage';
import { registerNewUser } from '@/api/user';
import { getOrganizationByInn } from '@/api/organization/index';
import { required, minLength, sameAs, email, helpers, maxLength, numeric } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    components: { LoginImage },
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        formData: {
            organization: { value: null, touched: false, id: null, type: '' },
        },
        tin: '',
        login: '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        agreement: true,
        organizationByInnError: '',
        innError: '',
        userError: '',
        organizationMessage: '',
        inputGroupClasses: ['p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-pblank-0', 'p-pb-2', 'p-px-2', 'p-pt-1'],
        submitted: false,
    }),
    validations() {
        const agree = () => this.agreement;
        const notHaveToMatch = () => {
            if (!this.login || !this.password) {
                return true;
            }
            return this.login !== this.password;
        }
        // const telephoneMask = () => /^((8|\+7)[- ]?)?(\(?\d{3,4}\)?[- ]?)?[\d\- ]{7,9}$/.test(this.telephone);
        const telephoneMask = val => {
            if (!val) {
                return true;
            }
            return /^[0-9-+)( ]+$/.test(val) && val.length > 1 && val.length < 256;
        }
        return {
            tin: {
                required: { ...required, $message: 'Поле обязательно к заполнению' },
                numeric: { ...numeric, $message: 'Допустимы только цифры' },
                minLength: { ...minLength(10), $message: 'Длина ИНН 10 цифр' },
                maxLength: { ...maxLength(10), $message: 'Длина ИНН 10 цифр' },
            },
            login: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: {
                    ...minLength(2),
                    $message: 'Логин не должен быть короче 2 символов',
                },
            },
            password: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: {
                    ...minLength(6),
                    $message: 'Пароль не должен быть короче 6 символов',
                },
                notHaveToMatch: helpers.withMessage('Логин и пароль не должны совпадать', notHaveToMatch),
            },
            passwordConfirm: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                sameAsPassword: {
                    ...sameAs(this.password),
                    $message: 'Пароли должны совпадать',
                },
            },
            firstName: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: {
                    ...minLength(2),
                    $message: 'Имя не должено быть короче 2 символов',
                },
            },
            lastName: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: {
                    ...minLength(2),
                    $message: 'Фамилия не должна быть короче 2 символов',
                },
            },
            email: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                email: {
                    ...email,
                    $message: 'Введите корректный email',
                },
            },
            telephone: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                telephoneMask: helpers.withMessage('Неверный формат телефона', telephoneMask)
            },
            agreement: {
                agree: agree,
            },
        };
    },
    methods: {
        async registerUser() {
            this.submitted = true;
            this.userError = '';
            this.innError = '';
            if (this.formData.organization.id == null) {
                this.organizationByInnError = 'Проведите поиск по ИНН';
                return;
            }
            if (!this.v$.$invalid) {
                const dataToServer = {
                    data: {
                        type: 'user',
                        id: null,
                        attributes: {
                            email: this.email,
                            login: this.login.toLowerCase(),
                            password: this.password,
                            firstName: this.firstName,
                            lastName: this.lastName,
                            patronymic: '',
                            phone: this.telephone,
                            workPosition: '',
                        },
                        relationships: {
                            organization: {
                                data: {
                                    type: this.formData.organization.type,
                                    id: this.formData.organization.id,
                                },
                            },
                        },
                    },
                };

                try {
                    const result = await registerNewUser(dataToServer);

                    if (result.message) {
                        this.organizationByInnError = result.message;
                        return;
                    }
                    await this.$router.push('/registrationConfirm');
                } catch (error) {
                    this.userError = error.message;
                }
            }
        },
        async findOrganization() {
            this.organizationByInnError = '';
            this.userError = '';
            this.innError = '';
            this.organizationMessage = false;
            this.formData.organization.value = 'Наименование Организации по результатам введенного ИНН';
            this.tinError = this.tin.length !== 10;
            if (this.tinError) {
                this.innError = 'Длина ИНН 10 цифр';
                return;
            }
            try {
                const response = await getOrganizationByInn(this.tin);
                const organization = response.shift();
                if (organization) {
                    this.formData.organization.value = organization.attributes.fullName;
                    this.formData.organization.id = organization.id;
                    this.formData.organization.type = organization.type;
                } else {
                    throw Error('Организация не найдена по ИНН');
                }
            } catch (error) {
                this.organizationByInnError = 'Организация не найдена по ИНН';
            }
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    computed: {
        organizationName() {
            let text = 'Наименование Организации по результатам введеннго ИНН';
            if (!this.formData.organization.value) return text;
            if (this.formData.organization.value) {
                text = this.formData.organization.value;
            }
            return text;
        },
    },
    watch: {
        tin() {
            this.organizationMessage = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.login-body .login-wrapper .login-panel .bgc {
    background: #f5fdf6;
    border-radius: 4px;
    padding: 0.5rem 1rem;

    p {
        font-weight: 400;
    }
}

.searchButtonDecorate {
    border: 1px solid #eaeaea;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.customPadding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.decorateText:hover {
    text-decoration: underline;
}

.login-body .login-wrapper .login-panel p {
    font-weight: 400;
}

.p-inputtext {
    padding: 0.5rem 1rem;
}

.login_title {
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #4a4a4a;
    margin-bottom: 0;
}

a {
    color: #388e3c;
    font-size: 14px;
    line-height: 14px;
}

.p-error {
    padding-top: 8px;
    text-align: left;
}

::v-deep .pass {
    .p-error {
        text-align: left;
    }
}

.telColor {
    color: #606060 !important;
}

.customColor {
    color: #656565;
}

.customLabelColor {
    color: #5f5f5f;
}

</style>
