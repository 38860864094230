import { render, staticRenderFns } from "./AppTopbar.vue?vue&type=template&id=068f1fc0&scoped=true&"
import script from "./AppTopbar.vue?vue&type=script&lang=js&"
export * from "./AppTopbar.vue?vue&type=script&lang=js&"
import style0 from "./AppTopbar.vue?vue&type=style&index=0&id=068f1fc0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068f1fc0",
  null
  
)

export default component.exports