<template>
    <transition name="fade">
        <div class="layout-topbar">
            <div class="topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-chevron-left icon-size"></i>
                </a>
                <span class="topbar-separator"></span>
                <router-link to="/person/userOrganization">
                    <div class="p-pl-2 p-d-flex p-ai-center p-py-2">
                        <template v-if="organization">
                            <img id="footer-logo" src="@/assets/img/org-logo.svg" alt="org-logo" /> <span class="title-font-size p-ml-2">{{ organization }}</span>
                            <!--  -->
                        </template>
                    </div>
                </router-link>

                <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            </div>

            <div class="topbar-right">
                <ul class="topbar-menu">
                    <li class="notifications-item" :class="{ 'active-menuitem ': topbarShow }">
                        <a href="#" tabindex="0" @click="topbarShow = !topbarShow">
                            <i class="pi pi-bell"></i>
                            <span v-if="unReadCount" class="topbar-badge">{{ unReadCount }}</span>
                        </a>
                        <ul class="notifications-menu">
                            <div class="p-d-flex">
                                <div class="constTitle">Уведомления</div>
                                <i
                                    class="pi pi-times"
                                    style="margin-top: 20px; color: #8794A3; cursor: pointer;"
                                    @click="topbarShow = !topbarShow">
                                </i>
                            </div>
                            <ScrollPanel style="width: 480px; height: 400px" class="custom-bar">
                                <Card
                                    class=""
                                    v-for="message in unReadNotifications"
                                    :key="message.id"
                                >
                                    <template #content>
                                        <a :href="message.link" target="_blank"
                                           @mouseover="notificationToRead(message)"
                                        >
                                            <div
                                                :class="[ message.attributes.read ? '' : 'greyBg', 'userContainer', 'p-d-flex' ]"
                                            >
                                                <span class="icon" :class="message.icon"></span>
                                                <div class="notification-item">
                                                    <div class="notification-summary">{{ message.name }}</div>
                                                    <div class="notification-detail">{{ message.attributes.fallbackText }}</div>
                                                </div>
                                                <div class="notification-date">{{ message.attributes.createdAt | dateTime }}</div>
                                            </div>
                                        </a>
                                    </template>
                                </Card>
                            </ScrollPanel>
                            <div v-if="showContinue" class="p-d-flex" style="justify-content: center; margin: 1rem;">
                                <button class="button-next" @click="getNotifications(true);">Ещё</button>
                            </div>
                        </ul>
                    </li>

                    <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
                        <router-link to="/person">
                            <!-- <img src="assets/demo/images/avatar/profile.jpg" alt="diamond-layout" class="profile-image" /> -->
                            <i class="pi pi-user p-mr-2 icon-size"></i>
                            <span class="profile-name user_name">{{ firstName }} {{ lastName }}</span>
                        </router-link>
                    </li>

                    <!--                <li class="right-sidebar-item">-->

                    <!--                </li>-->
                    <!--                <span class="topbar-separator"></span>-->
                    <Button @click.prevent="logout" label="Выход" icon="pi pi-sign-out" class="p-button-secondary p-button-text black"></Button>
                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { readUserNotification, getUserNotifications } from '@/api/notifications';
import { USER_NOTIFICATION_TITLES } from '@/constants/notifications';
import { socketUrl } from '@/api/api.js';

export default {
    name: 'AppTopbar',
    emits: ['menu-button-click', 'search-click', 'topbar-user-menu', 'right-menubutton-click'],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean,
    },
    data() {
        return {
            topbarShow: this.topbarNotificationMenuActive,
            sendNotificationTimeout: null,
            notificationsRead: [],
            unReadNotifications: [],
            unReadCount: null,
            showContinue: false,
            currentPage: 1
        };
    },
    created() {
        const channel = `notification#${ this.currentUser.id }`;

        this.initConnection(this.socketToken, channel);
    },

    async mounted() {
        await this.getNotifications();
    },

    methods: {
        initConnection(token, channel) {
            const Centrifuge = require('centrifuge');
            let centrifuge;

            if (centrifuge && centrifuge.isConnected()) {
                centrifuge.disconnect();
            }

            centrifuge = new Centrifuge(`${ socketUrl }/connection/websocket`, {
                debug: true
            });
            centrifuge.setToken(token);

            const that = this;
            centrifuge.on('connect', function () {
                let subscription;

                subscription = centrifuge.subscribe(channel, function (message) {
                    if (message.data.type === 'notifications-pushed' && message.data?.items.length > 0) {
                        message.data?.items.forEach(notification => {
                            notification = {
                                ...notification,
                                ...USER_NOTIFICATION_TITLES.find(item => item.type === notification.attributes.type)
                            };
                            let args = [
                                notification.meta.distributionId,
                                notification.meta.worksheetId,
                                notification.meta.fileUri,
                                notification.meta.organizationId,
                                notification.meta.userId
                            ];
                            notification.link = notification.format.replace(/{(\d+)}/g, function(match, number) {
                                return typeof args[number] != 'undefined'
                                    ? args[number]
                                    : match
                                    ;
                            });
                            that.unReadNotifications.unshift(notification);
                            if (!notification.attributes.read) {
                                that.unReadCount++;
                            }
                        });
                    }
                });

                subscription.on('error', function (message) {
                    console.log('Error subscribing on channel', message);
                });
            });

            centrifuge.connect();
        },

        async getNotifications(next = false) {
            if (next) {
                this.currentPage++;
            }

            const { data: notifications } = await getUserNotifications(this.currentPage);
            let result = notifications.data.map(message => {
                message = {
                    ...message,
                    ...USER_NOTIFICATION_TITLES.find(item => item.type === message.attributes.type)
                };
                let args = [
                    message.meta.distributionId,
                    message.meta.worksheetId,
                    message.meta.fileUri,
                    message.meta.organizationId,
                    message.meta.userId
                ];
                message.link = message.format.replace(/{(\d+)}/g, function (match, number) {
                    return typeof args[number] != 'undefined'
                        ? args[number]
                        : match
                        ;
                });
                return message;
            });
            if (next) {
                this.unReadNotifications = this.unReadNotifications.concat(result);
            } else {
                this.unReadNotifications = result;
            }
            this.currentPage = notifications.meta.pagination.current_page;
            if (notifications.meta.pagination.total_pages > this.currentPage) {
                this.showContinue = true;
            } else {
                this.showContinue = false;
            }
            this.unReadCount = notifications.meta.unreadCount;
        },

        async logout() {
            await this.$store.dispatch('auth/logout');
        },
        onMenuButtonClick(event) {
            this.$emit('menu-button-click', event);
        },

        notificationToRead(notification) {
            if (!this.notificationsRead.includes(notification.id) && !notification.attributes.read) {
                this.notificationsRead.push(notification.id);
            }
        },

        readNotification() {
            clearTimeout(this.sendNotificationTimeout);
            this.sendNotificationTimeout = setTimeout(this.sendNotification, 1000);
        },

        async sendNotification() {
            try {
                if (this.notificationsRead.length) {
                    const dataToServer = this.notificationsRead.map(id => {
                        return { id: id }
                    });
                    const response = await readUserNotification(dataToServer);
                    if (response.status === 204) {
                        this.notificationsRead = [];
                        await this.getNotifications();
                    }
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange')
            }
        }
    },
    watch: {
        notificationsRead: {
            handler(to) {
                if (to.length > 0) {
                    this.readNotification();
                }
            }
        },

        topbarNotificationMenuActive: {
            handler(to) {
                if (to.length > 0) {
                    this.topbarShow = this.topbarNotificationMenuActive;
                }
            }
        }
    },

    computed: {
        ...mapGetters('auth', [
            'firstName',
            'lastName',
            'organization',
            'socketToken',
            'currentUser'
        ]),
    }
};
</script>

<style lang="scss" scoped>
a {
    color: inherit;
}
a:hover {
    text-decoration: none;
}
.layout-topbar {
    .viewname {
        font-size: 0.95rem;
    }

    .topbar-menu > li > ul {
        padding: 0 !important;

        .notification-item {
            margin-left: 1rem;
        }

        .notification-summary {
            color: #495058;
            font-weight: 700 !important;
            font-size: 13px;
            line-height: 120%;
            padding-bottom: 6px;
        }

        .notification-detail {
            font-weight: 400;
            font-size: 13px;
            line-height: 120%;
        }

        .notification-date {
            position: absolute;
            color: #7A848C;
            font-weight: 400;
            font-size: 12px;
            right: 24px;
        }

    }

    .topbar-menu > li > a .topbar-badge {
        right: 4px;
        font-size: 0.75rem;
        font-weight: 400;
        background: #e34d25;
    }

    .topbar-menu .pi-bell {
        font-size: 1.2rem !important;
    }
}

.user_name {
    min-width: 105px;
    font-weight: 500;
    display: block;
    font-size: 13px;
    max-width: 105px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #272727;
}
.user_name:hover {
    color: #e34d25;
}
.profile-item {
    border: none;
}

.icon-size {
    font-size: 1rem !important;
}
.black {
    color: #272727 !important;
    font-size: 13px;
}

.layout-topbar .topbar-menu > li {
    margin-left: 0.5rem !important;
}

.title-font-size {
    font-size: 13px;
    color: #272727;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.customStyles {
    width: 480px;
    max-height: 400px;
    overflow: auto;
    z-index: 10;
}

.constTitle {
    font-size: 18px;
    line-height: 25px;
    color: #272727;
    padding: 1rem;
    width: 94%;
}
::v-deep .p-scrollpanel.custom-bar {
    .p-scrollpanel-content {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .p-scrollpanel-wrapper {
        border-right: 4px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
        width: 4px;
        background-color: rgb(193, 193, 193);
        opacity: 1;
        transition: background-color .2s;

        &:hover {
            background-color: rgb(193, 193, 193);
        }
    }

    .p-scrollpanel-bar-x {
        opacity: 0;
    }
}

::v-deep .p-card {
    width: 480px;
    box-shadow: none;

    .p-card-content {
        padding: 0;
    }

    .p-card-body {
        padding: 0 0 4px;
    }
}
.boldText {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 125%;
}

.userContainer {
    padding: 16px;
}

.greyBg {
    background: #F0F2F4;
}

.button-next {
    width: 56px;
    height: 30px;
    border: 1px solid #8794A3;
    border-radius: 3px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    background: #fff;
    cursor: pointer;
    z-index: 2000;
}
</style>
