import { apiUrl, axios } from '@/api/api.js'
import { getRefreshTokenId } from './authData';
import router from '@/router';
// export const apiUrl = process.env.VUE_APP_API_URL;

export async function refresh() {
    try {
        /* eslint-disable */
        const refreshTokenId = getRefreshTokenId();
        const result = await axios.post(`${ apiUrl }/auth/refresh`, {
            refreshTokenId,
            fingerPrint: 'string',
          },
          { withCredentials: true }
        );
        const accessData = result.data.data;

        return accessData ? accessData : false;
    } catch (error) {
        /** Кидаем на логин, если токен не получен */
        await router.push({ name: 'login' }).catch(() => {});
    }
}

export async function loginUser(data) {
  const authData = {
    ...data,
    fingerPrint: 'string',
  };
    // eslint-disable-next-line no-useless-catch
  try {
    const result = await axios.post(`${ apiUrl }/auth/login`, authData);
    const accessData = result.data.data;

    if (accessData) {
      return accessData.attributes;
    }

  } catch (error) {
    throw error;
  }
}

export async function autorizationAnotherUser(userId) {
  try {
    /* eslint-disable */
    const result = await axios.post(`${apiUrl}/auth/impersonate/${userId}`, {
      fingerPrint: 'string',
    });

    return result.data.data
  } catch (error) {
    let title = error.response.data.errors[0].title;
    console.log('error.response.title', error.response);
    switch (title) {
      case 'Credentials invalid.':
        throw new Error('Неверные имя пользователя или пароль');
      case 'User not verified.':
        throw new Error('Пользователь не подтвержден');
      default:
        if (title.indexOf('not found') >= 0) throw new Error('Пользователь не найден');
        else {
          throw new Error('Неизвестная ошибка');
        }
    }
  }
}


export async function recoverPassword(data) {
  try {
    await axios.post(`${apiUrl}/user/reset-password`, data);
    return true;
  } catch (error) {
      const {response: {data: {errors} }} = error
      if (error.isAxiosError) {
          let lastError = errors[errors.length - 1];
          throw new Error(lastError.detail);
      }
  }
}

export async function changePassword(data) {
    try {
        await axios.post(`${apiUrl}/auth/change-password`, data);
        return true;
    } catch (error) {
        const {response: {data: {errors} }} = error
        if (error.isAxiosError) {
            let lastError = errors[errors.length - 1]
            throw new Error(lastError.title);
        }
    }
}
