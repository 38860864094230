import router from '@/router';

export const generateErrors = (error, page = '') => {
    const {response: {data: {errors, id}, status}} = error
    let lastError = {};
    if (errors) {
        lastError = errors[errors.length - 1]
    }

    if (status === 502 || status === 504) {
        throw new Error('Время ожидания истекло. Попробуйте позже.');
    }

    if (status === 500) {
        throw new Error('Внутренняя ошибка сервера. Попробуйте позже.');
    }

    /** только для формы Логина */
    if (page === 'login' && (status === 404 || status === 401) && lastError.title) {
        if (lastError.title) {
            if (lastError.title === 'Пароль просрочен.') {
                router.push({ name: 'passwordChange' }).catch(() => {});
                return;
            } else {
                throw new Error('Неверный логин или пароль.');
            }
        } else {
            throw new Error(`В настоящее время сервис недоступен.<br/>Ведутся работы по исправлению.`);
        }
    }
    if (status === 404) {
        router.push({ name: 'notFound' }).catch(() => {});
        return;
    }
    if (status === 401) {
        throw new Error('');
    }
    if (status === 400) {
        throw new Error(lastError.title);
    }

    const fieldName = lastError.meta.propertyPath ? `Поле ${lastError.meta.propertyPath}` : ''
    const errorMessage = `${fieldName} ${lastError.title} ${lastError.detail} Код ошибки: ${ id }`
    throw new Error(errorMessage);
}

export const jsonApiListParser = (items, included = []) => {
    const result = []
    items?.forEach(item => {
        const resItem = { ...item.attributes, id: item.id }
        const { relationships } = item
        if (relationships) {
            Object.keys(relationships).forEach(relProp => {
                const relPropData = item.relationships[relProp].data || {}
                const ids = []
                if (Array.isArray(relPropData)) {
                    ids.push(...relPropData.map(i => i.id))
                } else if (relPropData.id) {
                    ids.push(relPropData.id)
                }
                if (ids.length > 0) {
                    const filteredInc = included.filter(i => ids.some(id => i.id === id))
                    if (filteredInc.length > 1) {
                        resItem[relProp] = filteredInc.map(incItem => {
                            return {...incItem.attributes, id: incItem.id}
                        })
                    } else if (filteredInc.length) {
                        resItem[relProp] = {...filteredInc[0].attributes, id: filteredInc[0].id}
                    }
                } else {
                    if (relPropData.id) {
                        const {id, type} = relPropData;
                        resItem[relProp] = {id, type};
                    } else {
                        resItem[relProp] = {};
                    }
                }
            })
        }
        result.push(resItem)
    })
    return result
}

export const downloadFile = (resultFileName, blobData) => {
    const link = document.createElement('a')
    link.download = resultFileName
    link.href = URL.createObjectURL(blobData)
    link.click()
    URL.revokeObjectURL(link.href)
}

export const downloadURI = (resultFileName, ext = '', uri) => {
    resultFileName = resultFileName.slice(0, 190);
    let link = document.createElement('a');
    link.href = `${ uri }?download=${ resultFileName }${ ext ? '.': '' }${ ext }`
    link.download = resultFileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
