import axios from 'axios';
import { getAccessToken, getStoreAuth } from './authData';
import router from '@/router';

const apiUrl = process.env.VUE_APP_API_URL
const socketUrl = process.env.VUE_APP_SOCKET_URL;

axios.interceptors.request.use(async request => {
  // console.log('request', request);

  const { common } = request.headers;
  const { url } = request;

  if (url) {
    if (url === `${apiUrl}/user` && request.method === 'post') return request;
    if (url === `${apiUrl}/user/reset-password` && request.method === 'post') return request;
    if (url === `${apiUrl}/organization` && request.method === 'post') return request;
    if (url === `${apiUrl}/auth/refresh`) return request
    if (url === `${apiUrl}/auth/login`) return request
    if (url === `${apiUrl}/auth/change-password`) return request
    if (url.includes('/organization/by-inn/')) return request
    if (url.includes('/requisites')) return request
  }

  const storeAuth = getStoreAuth();
  const accessTokenValid = await storeAuth.dispatch('AccessTokenCheck')

  if (accessTokenValid) {
    const accessToken = getAccessToken();
    if (
      !common.Authorization &&
      common.Authorization !== 'Bearer undefined' &&
      accessToken
    ) {
      request.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }

  return request;
});

axios.interceptors.response.use(response => response, async error => {
    const { response } = error;
    if (response && response.status === 401 && response.data.errors[0].code === 'spaceonfire\\DataSource\\EntityNotFoundException') {
        await router.push({ name: 'login' }).catch(() => {});
    }
    if (response === undefined) {
        return Promise.reject({ response: { data: {}, status: 504 } });
    }
    return Promise.reject(error)
})

export { axios, apiUrl, socketUrl };
