import Vue from 'vue';
import Router from 'vue-router';

import control from '@/router/modules/control';
import dataEntry from '@/router/modules/dataEntry';
import aggregate from '@/router/modules/aggregate';
import forms from '@/router/modules/forms';
import publicRoutes from '@/router/modules/publicRoutes';
import organizations from '@/router/modules/organizations';
import users from '@/router/modules/users';
import registers from '@/router/modules/registers';
import store from '@/store';
import { searchFirstRoute } from '@/utils/common';
import { USER_MENU_PERMS } from '@/constants/common';

Vue.use(Router);

let routes = [
    {
        path: '/FAQ',
        name: 'FAQ',
        redirect: { path: '/FAQ/FAQList' },
        component: () => import('@/pages/FAQ/index.vue'),
        meta: {
            isMain: true
        },
        children: [
            {
                path: 'FAQList',
                name: 'FAQList',
                component: () => import('@/pages/FAQ/FAQList.vue'),
                meta: {
                    breadcrumb: [
                        { to: '/FAQ', label: 'Справка' },
                        {
                            to: '/FAQ',
                            label: 'Список',
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '/forms',
        component: () => import('@/pages/forms/index.vue'),
        redirect: { path: '/forms/list' },
        name: 'forms',
        meta: {
            permissionAccess: USER_MENU_PERMS.FORMS,
        },
        children: forms,
    },
    {
        path: '/organizations',
        component: () => import('@/pages/organizations/Index.vue'),
        redirect: { path: '/organizations/list' },
        name: 'organizations',
        meta: {
            permissionAccess: USER_MENU_PERMS.ORGANIZATIONS
        },
        children: organizations,
    },
    {
        path: '/users',
        component: () => import('@/pages/users/index.vue'),
        redirect: { path: '/users/list' },
        name: 'users',
        meta: {
            permissionAccess: USER_MENU_PERMS.USERS.MAIN
        },
        children: users
    },
    {
        path: '/control',
        component: () => import('@/pages/control/ControlIndex.vue'),
        name: 'control',
        redirect: { path: '/control/notifications/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL
        },
        children: control,
    },
    {
        path: '/unloadingReports',
        name: 'unloadingReports',
        component: () => import('@/pages/unloadingReports/unloadingReports.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.UPLOADING_REPORTS,
        }
    },
    {
        path: '/dataEntry',
        name: 'dataentry',
        component: () => import('@/pages/dataEntry/index.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.DATA_ENTRY,
        },
        children: dataEntry
    },
    {
        path: '/aggregate',
        name: 'aggregate',
        component: () => import('@/pages/dataEntry/index.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.AGGREGATE
        },
        children: aggregate
    },
    {
        path: '/registers',
        name: 'registers',
        redirect: { path: '/registers/list' },
        component: () => import('@/pages/registers/index.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.REGISTERS
        },
        children: registers
    },
    ...publicRoutes,
    {
        path: '/person',
        component: () => import('@/pages/person/index.vue'),
        redirect: { path: '/person/userProfile' },
        children: [
            {
                path: 'userProfile',
                // name: 'userProfile',
                name: 'person',
                component: () => import('@/pages/person/userProfile'),
                meta: {},
            },
            {
                path: 'userOrganization',
                name: 'userOrganization',
                component: () => import('@/pages/person/userOrganization'),
                meta: {},
            },
        ],
        meta: {}
    },
    {
        path: '/:catchAll(.*)',
        name: 'notFound',
        component: () => import('@/pages/notFound/NotFound.vue'),
        meta: {},
    }
];

const router = new Router({
    mode: 'history',
    routes: routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

// const freeRoutes = ["/", '/login', '/registrationUser', '/registrationOrganization', '/registrationConfirm', '/passRecovery', '/passRecoveryConfirm', '/person/userProfile', '/FAQ', '/error', "/accessDenied"]
// const userRole = getCurrentUserRole()

// router.beforeEach(async (to, from, next) => {
//     await store.dispatch('auth/getUserBeforeReload');
//     // console.log(store.getters['auth/userPermissionsObject']);
//     next()
// })

router.beforeResolve(async (to, from, next) => {
    if (to.name === 'login') {
        next()
        return
    }
    await store.dispatch('auth/getUserBeforeReload');
    const userPermissions = store.getters['auth/userPermissionsObject']
    const { name: userFirstRouteName } = searchFirstRoute(routes, userPermissions) || {}
    const routePermissions = to.meta.permissionAccess
    if (to.meta.noProtected) {
        next()
        return
    }
    if (to.name !== 'login' && !store.state.auth.isAuth) {
        next({ name: 'login' })
        return
    }
    if (from.name === 'login' && !store.state.checkedAfterLogin) {
        store.commit('setCheckedAfterLogin', true)
        next({ name: userFirstRouteName })
        return
    }
    if (routePermissions && routePermissions.length && routePermissions.every(p => !userPermissions[p])) {
        next({ name: userFirstRouteName })
        return
    }
    // let userRole = store.getters['auth/currentUserRoleId']
    // console.log(' userRole', userRole);
    // //   // console.log('глобальный переход', userRole);
    // //   // console.log('router.app.$store', store.state.auth.user.userPermissions);

    // console.log('В', to,);
    // console.log('ИЗ', from);
    //   // console.log('to.meta.permissions', to.meta.permissions);
    //   // console.log('STOR', store.getters['auth/currentUserRoleId']);
    //   // console.log('from.meta.permissions', from.meta.permissions);
    //   // console.log('to.meta.permissions.includes(userRole)', to.meta.permissions.includes(getCurrentUserRole() || userRole))
    //   // console.log('freeRoutes.includes(to.fullPath)', freeRoutes.includes(to.fullPath))
    //   // console.log('store.state.auth', store.state.auth);
    //   // if (store.state.auth.user) {
    //   //   userRole = store.state.auth.user.role
    //   // }
    //   console.log('userRole', userRole);
    //   // if (userRole == null && !!localStorage.getItem('refreshTokenId')) {
    //   //   console.log('Нет роли есть токен иди на главную');
    //   //   next("/")
    //   //   return
    //   // }
    //   // if (to.fullPath == "/") {
    //   //   next()
    //   //   return
    //   // }
    //   if (freeRoutes.includes(to.fullPath)) {
    //     // console.log('Переход на свободный роут');
    //     next()
    //     return
    //   }
    //   else if (to.meta.permissions.includes(userRole)) {
    //     // console.log('Переход на РАЗРЕШЕННЫЙ для роли роут');
    //     next()
    //     return
    //   }
    //   else {
    //     // console.log('Переход на НЕ разрешенный для роли роут C', from.fullPath);
    //     next("/accessDenied")
    //     return
    //   }


    next();
});

export default router;

