import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import VueYandexMetrika from 'vue-yandex-metrika';
import serverFilters from '@/constants/serverFilters';
// import Vuex from 'vuex'
import store from '@/store';
import DomHandler from 'primevue/components/utils/DomHandler.js';
import vClickOutside from 'v-click-outside'

// import store from '@/store';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from '@/components/common/CalendarCustom';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
// import DataTable from 'primevue/datatable';
import DataTable from '@/components/common/datatableCustom/DataTable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
// import Menu from 'primevue/menu';
import Menu from '@/components/common/menu/Menu';
import MenuItem from 'primevue/components/menu/Menuitem'
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
// import TreeTable from 'primevue/treetable';
import TreeTable from '@/components/common/treetableCustom/TreeTable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import 'prismjs/themes/prism-coy.css';
import './App.scss';

import Vuelidate from 'vuelidate'
import MultiSelect26 from '@/components/common/MultiSelect26';


Vue.use(ToastService);
Vue.use(vClickOutside)

// Vue.use(router);
Vue.use(Vuelidate)
// Vue.use(Vuex)
// Vue.use(store);

Vue.use(VueYandexMetrika, {
    id: 94133637,
    router,
    env: process.env.VUE_APP_NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        triggerEvent: true
    }
})

Vue.use(VueYandexMetrika, {
    id: 43327374,
    router,
    env: process.env.VUE_APP_NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: false,
        triggerEvent: false
    }
})

Vue.directive('Tooltip', Tooltip);
Vue.directive('ripple', Ripple);

Vue.prototype.$appState = Vue.observable({ inputStyle: 'outlined' });
Vue.prototype.$primevue = Vue.observable({ ripple: true });

Vue.config.productionTip = false;

const FixedCheckbox = Vue.component("Checkbox", Checkbox).extend({
    methods: {
        onFocus(event) {
            // Если раскомментировать, то будет подсвечиваться при клике в массиве чекбоксов
            // this.focused = true;
            this.$emit('focus', event);
        },
    }
})

const fixedMultiSelect = Vue.component('MultiSelect', MultiSelect).extend({
    methods: {
        onClick() {
            if (!this.disabled && (!this.overlay || !this.overlay.contains(event.target)) && !DomHandler.hasClass(event.target, 'p-multiselect-close')) {
                // отключает сворачивание списка вариантов мультиселекта при выборе элемента
                // if (this.overlayVisible) {
                //     this.hide();
                // } else
                this.show();
                this.$refs.focusInput.focus();
            }
        },
    }
});

const FixedTabView = Vue.component('TabView', TabView).extend({
    methods: {
        onTabClick(event, tab) {
            this.$emit('tab-click', {
                originalEvent: event,
                index: tab
            });
        },
    }
})

Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('Button', Button);
Vue.component('Calendar', Calendar);
Vue.component('Card', Card);
Vue.component('Carousel', Carousel);
Vue.component('Chart', Chart);
Vue.component('Checkbox', FixedCheckbox);
Vue.component('Chips', Chips);
Vue.component('ColorPicker', ColorPicker);
Vue.component('Column', Column);
Vue.component('ContextMenu', ContextMenu);
Vue.component('DataTable', DataTable);
Vue.component('DataView', DataView);
Vue.component('DataViewLayoutOptions', DataViewLayoutOptions);
Vue.component('Dialog', Dialog);
Vue.component('Dropdown', Dropdown);
Vue.component('Fieldset', Fieldset);
Vue.component('FileUpload', FileUpload);
Vue.component('FullCalendar', FullCalendar);
Vue.component('InlineMessage', InlineMessage);
Vue.component('InputMask', InputMask);
Vue.component('InputNumber', InputNumber);
Vue.component('InputSwitch', InputSwitch);
Vue.component('InputText', InputText);
Vue.component('Galleria', Galleria);
Vue.component('Listbox', Listbox);
Vue.component('MegaMenu', MegaMenu);
Vue.component('Menu', Menu);
Vue.component('MenuItem', MenuItem);
Vue.component('Menubar', Menubar);
Vue.component('Message', Message);
//
Vue.component('MultiSelect', fixedMultiSelect);
Vue.component('MultiSelect26', MultiSelect26);
Vue.component('OrderList', OrderList);
Vue.component('OrganizationChart', OrganizationChart);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Paginator', Paginator);
Vue.component('Panel', Panel);
Vue.component('PanelMenu', PanelMenu);
Vue.component('Password', Password);
Vue.component('PickList', PickList);
Vue.component('ProgressBar', ProgressBar);
Vue.component('RadioButton', RadioButton);
Vue.component('Rating', Rating);
Vue.component('SelectButton', SelectButton);
Vue.component('ScrollPanel', ScrollPanel);
Vue.component('Slider', Slider);
Vue.component('Sidebar', Sidebar);
Vue.component('SplitButton', SplitButton);
Vue.component('Steps', Steps);
Vue.component('TabMenu', TabMenu);
Vue.component('TabView', TabView);
Vue.component('FixedTabView', FixedTabView);
Vue.component('TabPanel', TabPanel);
Vue.component('Textarea', Textarea);
Vue.component('TieredMenu', TieredMenu);
Vue.component('Toast', Toast);
Vue.component('Toolbar', Toolbar);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Tree', Tree);
Vue.component('TreeTable', TreeTable);
Vue.component('TriStateCheckbox', TriStateCheckbox);

// import Access from './pages/Access';
// import Error from './pages/Error';
// import Login from './pages/Login';
// import NotFound from './pages/NotFound';


import Error from '@/pages/error/Error.vue';
import Login from '@/pages/Login.vue';
import PassRecovery from '@/pages/password/PassRecovery.vue';
import PassRecoveryConfirm from '@/pages/password/PassRecoveryConfirm.vue';
import PasswordChange from '@/pages/password/PasswordChange.vue';
import PasswordChangeConfirm from '@/pages/password/PasswordChangeConfirm.vue';
import RegistrationUser from '@/pages/register/RegistrationUser.vue';
import RegistrationOrganization from '@/pages/register/RegistrationOrganization.vue';
import RegistrationConfirm from '@/pages/register/RegistrationConfirm.vue';
import AppGlobalLoader from '@/AppGlobalLoader.vue';
import { USER_PERMISSIONS_MAP } from '@/constants/common';

Vue.filter('date', function (value) {
    if (!value) { return ''; }
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    return new Date(value).toLocaleDateString('ru-RU', options);
})

import { dateTime } from '@/main/globalFilters';

Vue.filter('dateTime', function (value) {
    return dateTime(value);
})

Object.defineProperty(Vue.prototype, '$userPermissionsMap', { value: USER_PERMISSIONS_MAP })
Object.defineProperty(Vue.prototype, '$filters', { value: serverFilters })

new Vue({
    el: '#app',
    async mounted() {
        await this.$store.dispatch('auth/init');
    },
    computed: {
        ViewComponent() {
            if (this.$store.state.globalLoader) return AppGlobalLoader;
            if (!this.$store.state.auth.isAuth) {
                switch (this.$route.path) {
                    case '/passRecovery':
                        return PassRecovery;
                    case '/passRecoveryConfirm':
                        return PassRecoveryConfirm;
                    case '/passwordChange':
                        return PasswordChange;
                    case '/passwordChangeConfirm':
                        return PasswordChangeConfirm;
                    case '/registrationConfirm':
                        return RegistrationConfirm;
                    case '/registrationOrganization':
                        return RegistrationOrganization;
                    case '/registrationUser':
                        return RegistrationUser;
                    case '/error':
                        return Error;
                    default:
                        // router.push('/login');
                        return Login;
                }
            }
            switch (this.$route.path) {
                case '/login':
                    return Login;
                case '/passRecovery':
                    return PassRecovery;
                case '/passRecoveryConfirm':
                    return PassRecoveryConfirm;
                case '/registrationConfirm':
                    return RegistrationConfirm;
                case '/registrationOrganization':
                    return RegistrationOrganization;
                case '/registrationUser':
                    return RegistrationUser;
                case '/error':
                    return Error;
                default:
                    return App;
            }

        },
    },
    router,
    store,
    render(h) {
        return h(this.ViewComponent);
    },
    methods: {
        reachGoal(goal) {
            try {
                if (this.$metrika) {
                    this.$metrika.reachGoal(goal);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
});
