<template>
    <div class="login-body">
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel">
                <form @submit.prevent="userLogin" class="login-form">
                    <img class="p-mb-6" src="assets/layout/images/login-logo.svg" alt="diamond-layout" />

                    <div class="p-d-flex p-ai-center p-col-12 p-pl-0 p-pt-0">
                        <h3 class="login_title p-mt-0">Вход в систему</h3>
                    </div>

                    <div :class="inputGroupClasses" class="p-mt-1">
                        <InputText autocomplete="true" name="login" v-model="v$.ulogin.$model" placeholder="Логин" :class="v$.ulogin.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.ulogin)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                    </div>
                    <div :class="{'p-error': (v$.password.$invalid && submitted)}" class="p-field p-fluid p-d-flex p-flex-column p-ai-start p-col-12 p-pblank-0">
                        <Password autocomplete="off" :feedback="false" name="password" placeholder="Пароль" v-model="v$.password.$model" />
                        <small v-show="submitted" v-for="error in showErrors(v$.password)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                        <small class="p-error" v-html="authorizationFailed" />
                    </div>
                    <div class="p-mb-0 p-d-flex p-ai-end">
                        <Button label="Вход" type="submit" class="p-button p-component p-px-6"></Button>
                    </div>

                    <router-link to="/passRecovery" class="anc-decorate p-mt-3"> Забыли пароль? </router-link>
                    <router-link to="/registrationUser" class="anc-decorate p-mt-1"> Регистрация пользователя </router-link>
                    <router-link to="/registrationOrganization" class="anc-decorate p-mt-1"> Регистрация организации </router-link>
                </form>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import LoginImage from '@/components/loginComponents/LoginImage';
import { mapActions } from 'vuex';

import { required, minLength, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'login',
    components: {
        LoginImage,
    },
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        loading: false,
        sidebar: false,
        authFall: false,
        ulogin: '',
        password: '',
        accessData: null,
        accessTokenInfo: null,
        submitted: false,
        authorizationFailed: '',
        inputGroupClasses: ['p-field', 'p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-pblank-0'],
    }),

    validations() {
        const notHaveToMatch = () => this.ulogin !== this.password;
        return {
            ulogin: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: { ...minLength(2), $message: 'Длина не менее 2 цифр' },
            },
            password: {
                required: { ...required, $message: 'Поле не должно быть пустым' },
                minLength: { ...minLength(6), $message: 'Длина не менее 6 цифр' },
                notHaveToMatch: helpers.withMessage('Неверный логин или пароль. Повторите попытку', notHaveToMatch),
            },
        };
    },

    methods: {
        ...mapActions('auth', ['login']),

        async userLogin() {
            this.submitted = true;
            this.loading = true
            if (!this.v$.$invalid) {
                try {
                    const result = await this.login({ login: this.ulogin, password: this.password });
                    if (result.message) {
                        this.authorizationFailed = result.message;
                        return;
                    }
                    await this.$router.push('/').catch(() => {});
                } catch (error) {
                    this.authorizationFailed = error.message;
                }
            }
            this.loading = false
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    computed: {
        isAuth() {
            return this.$store.state.auth.isAuth;
        },
        // authorizationFailed() {
        //     if (this.authFall) return 'Не правильное имя пользователя или пароль';
        //     return '';
        // },
    },
    watch: {
        login: function (newVal, oldVal) {
            newVal != oldVal ? (this.authorizationFailed = '') : null;
        },
        password: function (newVal, oldVal) {
            newVal != oldVal ? (this.authorizationFailed = '') : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.login_title {
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #4a4a4a;
    margin-bottom: 0;
}

.login-form {
    min-width: 270px !important;
}

.anc-decorate {
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #656565;
}

.p-component-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.p-fluid {
    padding: 0 !important;
}

.p-error .p-inputtext.p-component {
    border-color: #f44336 !important;
}

::v-deep .login-panel small {
    text-align: left;
}
</style>

