import { autorizationAnotherUser, loginUser, refresh } from '@/api/auth';
import router from '@/router/index.js';
import jwtDecode from 'jwt-decode';
import { me } from '@/api/user';
import { setStateAuthInjectionToApi } from '@/api/authData';
import { generateErrors } from '@/main/utils/common';

export const namespaced = true;

export const state = () => ({
    isAuth: false,
    user: {},
    refreshTokenId: null,
    accessToken: null,
    routesWithoutAuthFullPath: ['/login', '/registrationUser', '/registrationOrganization', '/registrationConfirm', '/passRecovery', '/passRecoveryConfirm', '/passwordChange', '/passwordChangeConfirm', '/error'],
   // notifications: [],
});

export const mutations = {
    clearAccessData(state) {
        state.isAuth = false;
        state.user = {};
        state.refreshTokenId = null;
        state.accessToken = null;
        localStorage.removeItem('refreshTokenId');
    },

    setAccessData(state, payload) {
        state.refreshTokenId = payload.refreshTokenId;
        state.accessToken = payload.accessToken;
        localStorage.setItem('refreshTokenId', payload.refreshTokenId);
    },

    setLoginData(state, payload) {
        state.login = payload.login;
    },

    setAuthStatus(state, payload) {
        state.isAuth = payload;
    },

    setRefreshTokenId(state, payload) {
        state.refreshTokenId = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    }
};

export const actions = {
    async init(ctx) {
        setStateAuthInjectionToApi(ctx);

        if (!ctx.state.refreshTokenId) {

            const refreshTokenIdLS = localStorage.getItem('refreshTokenId');

            if (!refreshTokenIdLS) {
                await ctx.commit('clearAccessData');
                ctx.commit('setGlobalLoader', false, { root: true });
                return;
            }
            // Нужно для refresh, т.к. есть инъекция в api/authData из StoreStateAuth
            ctx.commit('setRefreshTokenId', refreshTokenIdLS);
        }
        // await ctx.dispatch('getMe');
        // await ctx.dispatch('getNotifications')

        // ctx.commit('setGlobalLoader', false, { root: true });

    },

    async getUserBeforeReload(context) {
        if ('userPermissions' in context.state.user || !context.state.refreshTokenId) return;
        await context.dispatch('getMe');
        context.commit('setGlobalLoader', false, { root: true });
    },

    async login(ctx, payload) {
        try {
            const accessDataAttributes = await loginUser(payload);
            if (accessDataAttributes) {
                ctx.commit('setAccessData', accessDataAttributes);
                localStorage.removeItem('dataEntryFilter');
                localStorage.removeItem('aggregateFilter');
                await ctx.dispatch('getMe');
                return true;
            }
        } catch (error) {
            ctx.commit('setLoginData', payload);
            generateErrors(error, 'login');
        }
    },

    async logout(ctx) {
        await router.push('/login');
        ctx.commit('clearAccessData');
    },

    async getMe(ctx) {
        try {
            const user = await me();
            ctx.commit('setUser', user);
            ctx.commit('setAuthStatus', true);
            const a = {};
            user.userPermissions.forEach(i => {
                a[i.id] = i.id;
            });
        } catch {
            await ctx.commit('clearAccessData');

        }
        // ctx.commit('setGlobalLoader', false, { root: true });
        // return;

        // const user = {
        //   id: 1,
        //   email: "mail.ru",
        //   firstName: 'Vitya',
        //   lastName: 'Alvarez',
        //   secondName: "Second name",
        //   organization: "НИИ урологии и интервенционной радиологии им. Н.А. Лопаткина – филиал ФГБУ «НМИЦ радиологии» Минздрава России"
        // }
        // console.log('user', user);

    },
    isAccessTokenValid(ctx) {
        if (!ctx.state.accessToken) return false;
        const accessTokenInfo = jwtDecode(ctx.state.accessToken);
        if (!accessTokenInfo) return false;
        const { exp } = accessTokenInfo;
        return parseFloat(exp) * 1000 >= +new Date();
    },

    async AccessTokenCheck(ctx) {
        try {
            const accessTokenValid = await ctx.dispatch('isAccessTokenValid');
            if (!accessTokenValid) {
                const refreshResult = await refresh();

                if (!refreshResult) {
                    await router.push('/login');
                    ctx.commit('setGlobalLoader', false, { root: true });
                    ctx.commit('clearAccessData');
                    return;
                }
                ctx.commit('setAccessData', refreshResult.attributes);
                return true;
            }
            return accessTokenValid;
        } catch (e) {
            console.log('referr', e);

        }

    },

    async authAnotherUser({ dispatch, commit, rootState }, userId) {
        rootState.rootEmit('loadingChange', true)
        try {
            const { attributes } = await autorizationAnotherUser(userId)
            commit('clearAccessData');
            commit('setAccessData', attributes);
            await dispatch('getMe');
        } catch (error) {
            console.log(error);
        } finally {
            rootState.rootEmit('loadingChange', false)
        }
    }

};

export const getters = {
    accessTokenInfo: state => {
        if (!state.accessToken) return null;
        return jwtDecode(state.accessToken) || null;
    },
    socketToken: state => {
        return state.accessToken;
    },
    currentUser(state) {
        return state.user;
    },
    lastLogin(state) {
        return state.login;
    },
    currentUserRoleId(state) {
        return state.user.role.id;
    },
    firstName(state) {
        return state.user.firstName;
    },
    lastName(state) {
        return state.user.lastName;
    },
    organization(state) {
        return state.user.organization;
    },
    organizationObj(state) {
        return state.user.organizationObj;
    },
    notifications(state) {
        return state.notifications;
    },

    userPermissionsObject(state) {
        const { user } = state;
        if (!user) {
            return {};
        }
        const { userPermissions } = user;
        if (!userPermissions) {
            return {};
        }
        const result = {};
        state.user.userPermissions.forEach(({ id }) => { result[id] = true });
        return result;
    },

    formPermissionsObject(state) {
        const { user } = state;
        if (!user) {
            return {};
        }
        const { formPermissions } = user;
        if (!formPermissions) {
            return {};
        }
        const result = {};
        state.user.formPermissions.forEach(({ form, operation }) => {
            if (!result[form.id]) {
                result[form.id] = {
                    read: false,
                    write: false,
                    approve: false,
                    distribute: false,
                    report: false,
                    review: false,
                };
            }
            result[form.id][operation] = true;
        });
        return result;
    },

    userHasRoles: (state, getters) => roles => roles.some(role => getters.userPermissionsObject[role]),
    checkPermissionAccess: (state, getters) => item => {
        return (!item.permissionAccess || !item.permissionAccess.length) || item.permissionAccess.some(p => getters.userPermissionsObject[p])
    },
    // accessibleListMenuItems: (state, getters) => listMenuItems => {
    //     return listMenuItems.filter(i => getters.checkPermissionAccess(i))
    // },
    accessibleItems: (state, getters) => items => {
        return items.filter(i => getters.checkPermissionAccess(i))
    }
};
